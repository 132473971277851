<div [class.dark-theme]="isDark">
  <!-- <mat-toolbar class="nav-header" color="primary"> -->
  <mat-toolbar class="nav-header" [color]="themeColor">
    <mat-toolbar-row>
      <!-- <div fxLayoutGap="15px" fxFlex="20%"> -->
      <div fxLayoutGap.lt-md="5px" fxLayoutGap.gt-sm="20px" fxLayoutGap.gt-md="30px">
        <button mat-icon-button (click)="toggleSidenav()" fxShow="true" fxHide.gt-sm>
          <mat-icon>menu</mat-icon>
        </button>
        <a routerLink="/" mat-button routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          style="padding: 0px;">
          <img
            src="https://website-images-nathan.s3-us-west-2.amazonaws.com/nathanpickard.com/Blue+Elegant+Badge+Soccer+Logo.svg"
            alt="logo" style="width: 45px;">
        </a>
        <a mat-button routerLink="/blog" routerLinkActive="active" fxHide.lt-sm>
          Blog
        </a>
        <a mat-button routerLink="/portfolio" routerLinkActive="active" fxHide.lt-sm>
          Portfolio
        </a>
        <a mat-button routerLink="/about" routerLinkActive="active" fxHide.lt-sm>
          About
        </a>
      </div>

      <span class="spacer"></span>

      <!-- <button mat-icon-button (click)="toggleTheme()" matTooltip="Toggle dark theme"> -->
        
        <!-- <div fxLayoutGap="10px" fxLayoutAlign="space-around center" fxFlex="20%"> -->
      <div class="social-media" fxLayoutGap.lt-sm="2px" fxLayoutGap.gt-sm="15px" fxLayoutGap.lt-md="5px"
      fxLayoutAlign="space-around center" fxFlex.gt-md="20%">
        <button mat-icon-button (click)="toggleDarkTheme($event)" matTooltip="Toggle dark theme">
          <mat-icon>
            brightness_6
          </mat-icon>
        </button>
        <a href="https://twitter.com/NathanPickard" mat-icon-button target="_blank">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
          </svg>
        </a>
        <a href="https://github.com/NathanPickard" mat-icon-button target="_blank">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z" />
          </svg>
        </a>
        <a href="https://www.linkedin.com/in/nathanpickard/" mat-icon-button target="_blank">
          <svg style="width:28px;height:28px" viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />
          </svg>
        </a>
        <a href="https://dev.to/nathanpickard" mat-icon-button target="_blank">
          <svg width="24" height="24" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ffffff"
              d="M 50 0C 22 0 0 22 0 50C 0 50 0 950 0 950C 0 978 22 1000 50 1000C 50 1000 950 1000 950 1000C 978 1000 1000 978 1000 950C 1000 950 1000 50 1000 50C 1000 22 978 0 950 0C 950 0 50 0 50 0C 50 0 50 0 50 0M 50 25C 50 25 950 25 950 25C 964 25 975 36 975 50C 975 50 975 950 975 950C 975 964 964 975 950 975C 950 975 50 975 50 975C 36 975 25 964 25 950C 25 950 25 50 25 50C 25 36 36 25 50 25C 50 25 50 25 50 25 M 633 391C 633 391 610 304 610 304C 610 304 649 304 649 304C 670 304 688 306 688 307C 688 310 751 558 754 558C 756 558 769 522 791 439C 791 439 825 307 825 307C 825 307 863 306 863 306C 884 304 901 306 901 307C 901 315 815 638 808 654C 800 676 776 695 757 695C 737 695 720 674 711 656C 701 638 661 475 633 391C 633 391 633 391 633 391M 383 320C 395 304 396 304 481 304C 481 304 566 304 566 304C 566 304 566 371 566 371C 566 371 438 371 438 371C 438 371 438 463 438 463C 438 463 517 463 517 463C 517 463 517 529 517 529C 517 529 479 531 479 531C 479 531 441 533 441 533C 441 533 441 625 441 625C 441 625 503 626 503 626C 503 626 566 628 566 628C 566 628 566 695 566 695C 566 695 483 695 483 695C 403 695 400 694 386 680C 373 664 372 665 370 501C 370 344 371 336 383 320C 383 320 383 320 383 320M 243 383C 233 375 219 371 199 371C 199 371 169 371 169 371C 169 371 170 498 170 498C 170 498 172 625 172 625C 172 625 201 624 201 624C 223 624 234 620 244 611C 257 598 258 592 258 496C 258 397 257 395 243 383C 243 383 243 383 243 383M 102 500C 102 500 102 304 102 304C 102 304 175 304 175 304C 261 304 287 314 310 351C 323 373 325 382 327 484C 329 600 325 626 302 655C 279 685 247 695 171 695C 171 695 102 695 102 695C 102 695 102 500 102 500" />
          </svg>
        </a>
        <a href="mailto: nathan.pickard87@gmail.com" mat-icon-button target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
          </svg>
        </a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>