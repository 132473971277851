import { Component } from '@angular/core';

@Component({
  template: `
  <div style="margin: 50px;">
    <h1>404 - Page Not Found</h1>
  </div>
  `,
  styles: [``]
})
export class NotFoundComponent { }