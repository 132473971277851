<div fxLayout="column" fxLayoutAlign="center center">

  <h2>Hey there!</h2>
  <!-- <p style="max-width: 700px; width: 85%; text-align: center">
  </p> -->

  <div style="margin: 20px 20px;">
    <div fxLayout="row wrap" fxLayoutGap="30px grid" fxLayoutAlign="center center">
      <a class="twitter-timeline" data-width="400" data-height="500" data-theme="light"
        href="https://twitter.com/NathanPickard?ref_src=twsrc%5Etfw">Tweets by NathanPickard
      </a>
      <div fxLayout="column">
        <h3>Recent GitHub projects:</h3>
        <div fxLayoutGap="15px" fxLayout="column">
          <div *ngFor="let recentProject of recentProjects">
            <mat-card style="max-width: 350px;">
              <mat-card-header>
                <mat-card-title>
                  <a href="{{ recentProject.html_url }}" target="_blank">
                    {{ recentProject.name }}
                  </a>
                </mat-card-title>
                <mat-card-subtitle>
                  Updated: {{ recentProject.updated_at | date: 'shortDate'}}
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                {{ recentProject.description }}
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>